<template>
  <!-- 推送样本库 -->
  <a-modal
    v-model="pushModal"
    :width='800'
    title="推送到样本库"
    ok-text="确认"
    cancel-text="取消"
    @cancel="cancelPushModal"
    @ok="confirmPushModal"
  >
    <div class="push-modal">
      <div>
        <!-- 选择大币种 -->
        <a-select
          :dropdownMatchSelectWidth="false"
          @change="changeCoin"
          @search="coinSearch"
          :filter-option="untils.filterOption"
          allowClear
          showSearch
          size="small"
          style="margin: 5px; width: 180px"
          v-model="curItem.coinKindId"
          placeholder="请选择大币种"
        >
          <a-select-option
            v-for="item of coinList"
            :key="item.id"
            :value="item.id"
          >{{ item.coinKindName }}</a-select-option>
        </a-select>
        <!-- 选择小币种 -->
        <a-select
          :dropdownMatchSelectWidth="false"
          @change="changeCoinItem"
          showSearch
          :filter-option="untils.filterOption"
          allowClear
          size="small"
          style="margin: 5px; width: 180px"
          v-model="curItem.coinKindItemId"
          placeholder="请选择小币种"
        >
          <a-select-option
            v-for="item of coinItemList"
            :key="item.id"
            :value="item.id"
          >{{ item.coinKindItemName }}</a-select-option>
        </a-select>
        <!-- 选择版别 -->
        <a-select
          :dropdownMatchSelectWidth="false"
          allowClear
          showSearch
          @change="changeVersion"
          :filter-option="untils.filterOption"
          size="small"
          style="margin: 5px; width: 180px"
          v-model="curItem.coinKindItemVersionId"
          :dropdownMenuStyle="{'max-height': '500px'}"
          placeholder="版别"
        >
          <a-select-option
            v-for="item of versionList"
            :key="item.id"
            :value="item.id"
          >{{ `${item.coinKindVersionName}` }}</a-select-option>
        </a-select>
      </div>
      <div class="images">
        <div
          class="image-item"
          v-for="(item, index) in curItem.covers"
          :key="index"
        >
          <img
            class="image"
            :src="item"
          />
          <!-- <a-popconfirm
            title="Are you sure delete this item?"
            ok-text="Yes"
            cancel-text="No"
            @confirm="deleteImage(index)"
          >
            <a-button
              size="small"
              type="danger"
            >删除</a-button>
          </a-popconfirm> -->
        </div>

      </div>
    </div>

  </a-modal>
</template>

<script>
import untils from "@/untils";
export default {
  data() {
    return {
      untils,
      pushModal: false,
      curItem: {},
      coinList: [],
      coinItemList: [],
      versionList: [],
    };
  },
  methods: {
    show(data) {
      this.curItem = JSON.parse(JSON.stringify(data));
      this.setDefault();
      this.pushModal = true;
    },
    // 默认值
    async setDefault() {
      const res = await this.axios("/dq_admin/kind/list", {
        params: { coinName: this.curItem.coinName },
      });
      if (res.status != "200") return;
      const { records } = res.data;
      this.coinList = records;
      this.curItem.coinKindId = this.coinList[0].id;
      this.curItem.coinSid = this.coinList[0].sid;
      this.curItem.coinKindName = this.coinList[0].coinKindName;
      this.coinItemSearch();

      // 图片
      const { ImageInfoUrl } = this.curItem;
      this.$set(this.curItem, "covers", ImageInfoUrl);
    },
    // 大币种改变
    changeCoin(val) {
      delete this.curItem.coinKindItemId;
      delete this.curItem.coinKindItemName;
      delete this.curItem.coinKindItemVersionId;
      delete this.curItem.coinKindItemVersionName;
      if (!val) return;
      const coin = this.coinList.find((el) => {
        return el.id == this.curItem.coinKindId;
      });
      this.curItem.coinSid = coin.sid;
      this.curItem.coinKindName = coin.coinKindName;

      this.coinItemSearch();
    },
    //  选择小币种
    changeCoinItem(val) {
      delete this.curItem.coinKindItemVersionId;
      delete this.curItem.coinKindItemVersionName;
      if (!val) return;
      const coinItem = this.coinItemList.find((el) => {
        return el.id == this.curItem.coinKindItemId;
      });
      this.curItem.coinKindItemName = coinItem.coinKindItemName;
      this.versionList = coinItem.coinItemVersionList;
    },
    // 版别改变
    changeVersion(val) {
      if (!val) return;
      const version = this.versionList.find((el) => {
        return el.id == this.curItem.coinKindItemVersionId;
      });
      this.curItem.coinKindItemVersionName = version.coinKindVersionName;
    },
    // 删除图片
    deleteImage(index) {
      this.curItem.covers.splice(index, 1);
    },

    // 搜索，大币种搜索
    async coinSearch(str) {
      if (!str) return;
      const res = await this.axios("/dq_admin/kind/list", {
        params: { coinName: str },
      });
      if (res.status != "200") return;
      const { records } = res.data;
      this.coinList = records;
    },

    // 搜索小币种 +版别
    async coinItemSearch() {
      const res = await this.axios("/dq_admin/kind/getItemAndVersionNew", {
        params: { sid: this.curItem.coinSid },
      });
      const { coinItem } = res.data;
      this.coinItemList = coinItem;
    },
    // 关闭
    cancelPushModal() {
      this.pushModal = false;
    },
    // 确认
    async confirmPushModal() {
      const {
        spliderId,
        coinKindId,
        coinKindName,
        coinKindItemId,
        coinKindItemName,
        coinKindItemVersionId,
        coinKindItemVersionName,
        covers,
      } = this.curItem;
      const coverImages = [covers.join(",")];
      const res = await this.axios.post(
        "/dq_admin/ratingSplider/pushAsynDiku",
        {
          type: 0, //泉海
          id: spliderId,
          coinKindId,
          coinKindName,
          coinKindItemId,
          coinKindItemName,
          coinKindItemVersionId,
          coinKindItemVersionName,
          coverImages,
        }
      );
      if (res.status != 200) return;
      this.cancelPushModal();
      this.$message.success(res.message || "操作成功");
      this.$emit("success");
    },
  },
};
</script>

<style lang="scss" scoped>
.push-modal {
  .images {
    margin-top: 10px;
    display: flex;
    .image-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0 10px 10px 0;
      .image {
        min-width: 200px;
        height: 200px;
        margin-bottom: 10px;
        background: #f2f2f2;
        border-radius: 4px;
      }
    }
  }
}
</style>